import { useState, lazy, Suspense, useContext } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import fbIcon from "../assets/fb icon.svg";
import twitterIcon from "../assets/twitter icon.svg";
import linkedinIcon from "../assets/linkedin icon.svg";
import pinterestIcon from "../assets/pinterest icon.svg";
import { SplitDate } from "../utils/SplitDate";
import { Link } from "react-router-dom";
import { AppContext } from "../App";
import logo from "../assets/transparentexpred.webp";
const Login = lazy(() => import("../authentication/Auth/LoginComponent"));

const FooterCard = styled(Box)(({ theme }) => ({
  display: "flex",
  background: "#D9D9D9",
  borderRadius: "5px",
  padding: "0 2%",
  marginBottom: "15px",
  marginTop: "15px",
  height: "auto",
}));
const FooterBox = styled(Box)(({ theme }) => ({
  padding: "4% 2%",
  background: "#FFFFFF",
  borderRadius: "5px",
  [theme.breakpoints.down("md")]: {
    padding: "1%",
  },
}));
const DateBox = styled(Box)({
  margin: "2%",
  width: "50%",
  background: "#000000",
  display: "flex",
  alignItems: "center",
  borderRadius: "5px",
  lineHeight: "18px",
  fontSize: "12px",
});

const DiscussionCard = styled(Box)(({ theme }) => ({
  background: "#D9D9D9",
  // background: "#f5f5f5",
  borderRadius: "5px",
  padding: "12px",
  marginBottom: "12px",
  transition: "all 0.3s ease",
  "&:hover": {
    background: "#e0e0e0",
    transform: "translateY(-2px)",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },
}));

const ExamCodeBadge = styled(Chip)(({ theme }) => ({
  background: "black",
  color: "white",
  fontWeight: "bold",
  marginRight: "8px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

function Footer({ loading, exams, discussions }) {
  const { token } = useContext(AppContext);
  const [state, setState] = useState({
    login: false,
    register: false,
  });

  const toggle = (name) => {
    if (name === "register") {
      setState((prev) => ({ ...prev, [name]: !prev[name], login: false }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };

  return (
    <Box
      sx={{
        pb: 5,
        mt: 5,
        minHeight: "500px",
        background: "black",
        zIndex: 998,
        position: "relative",
      }}
    >
      <Container>
        <Stack
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box sx={{ width: "100%", minHeight: "500px" }}>
            <Grid container spacing={4} sx={{ pt: 5 }}>
              <Grid item md={4} xs={12}>
                <FooterBox sx={{ height: "456px" }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "28px",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                    }}
                  >
                    New Exams
                  </Typography>
                  {loading && (
                    <Stack
                      mt={1}
                      width="100%"
                      direction="row"
                      justifyContent="center"
                    >
                      <CircularProgress disableShrink />
                    </Stack>
                  )}
                  <Box sx={{ mt: 1, height: "410px", overflow: "auto" }}>
                    {!loading &&
                      [...exams]
                        .sort((a, b) => new Date(b.Date) - new Date(a.Date))
                        ?.map((exam, i) => {
                          return (
                            <Link
                              key={i}
                              to={`/${encodeURIComponent(
                                exam.ExamVendorName.replace(/ /g, "-")
                              )
                                .toLowerCase()
                                .replace(
                                  /%2b/g,
                                  "p"
                                )}/${exam.ExamCode.toLowerCase()}`}
                              state={exam}
                              style={{ color: "black", textDecoration: "none" }}
                            >
                              <FooterCard>
                                <Stack width="75%" justifyContent="flex-start">
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {/* {exam?.ExamCode.substring(0, 19)} */}
                                    {exam?.ExamCode}
                                  </Typography>
                                  <Typography
                                    sx={{ mt: 0.5, fontSize: "12px" }}
                                  >
                                    {/* {exam?.ExamName.substring(0, 29)} */}
                                    {exam?.ExamName}
                                  </Typography>
                                </Stack>
                                <DateBox>
                                  <Stack
                                    width="100%"
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-evenly"
                                  >
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontSize: "16px",
                                          color: "white",
                                        }}
                                      >
                                        {SplitDate(exam?.Date).day}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "12px",
                                          color: "white",
                                        }}
                                      >
                                        {SplitDate(exam?.Date).month}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{ borderLeft: "1px solid #D9D9D9" }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color: "white",
                                          pl: 1,
                                        }}
                                      >
                                        {SplitDate(exam?.Date).year}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </DateBox>
                              </FooterCard>
                            </Link>
                          );
                        })}
                  </Box>
                </FooterBox>
              </Grid>

              <Grid item md={4} xs={12}>
                <FooterBox sx={{ height: "456px" }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "28px",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                    }}
                  >
                    Recent Discussions
                  </Typography>
                  <Box
                    sx={{
                      height: { xs: "380px", md: "410px" },
                      overflow: "auto",
                      px: 1,
                      mt: 2,
                    }}
                  >
                    {discussions.map((discussion, index) => (
                      <Link
                        key={index}
                        to={`/${encodeURIComponent(
                          discussion.ExamVendorName.replace(/ /g, "-")
                        )
                          .toLowerCase()
                          .replace(
                            /%2b/g,
                            "p"
                          )}/${discussion.ExamCode.toLowerCase()}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <DiscussionCard>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            mb={1}
                          >
                            <ExamCodeBadge label={discussion.ExamCode} />
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {new Date(
                                discussion.CreatedOn
                              ).toLocaleDateString()}
                            </Typography>
                          </Stack>
                          <Typography
                            variant="body2"
                            mb={2}
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {discussion.Remarks}
                          </Typography>
                        </DiscussionCard>
                        {index < discussions.length - 1 && <Divider />}
                      </Link>
                    ))}
                  </Box>
                </FooterBox>
              </Grid>
              <Grid item md={4} xs={12}>
                <Stack
                  ml={{ xs: 0, md: 4 }}
                  spacing={4}
                  px={{ xs: "5px", md: "10px" }}
                >
                  <Link to="/" style={{ margin: "auto", display: "block" }}>
                    <Box
                      component="img"
                      src={logo}
                      alt="DirectCertify Logo"
                      width={250}
                      height={65}
                      loading="lazy"
                      sx={{
                        display: "block",
                        margin: "0 auto",
                        maxWidth: "100%",
                        height: "auto",
                        objectFit: "contain",
                        aspectRatio: "250 / 65",
                      }}
                    />
                  </Link>
                  {!token && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "flex-start" },
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          background: "#27B1BB !important",
                          border: "1px solid #D9D9D9",
                          boxShadow: "0px 4px 0px #D9D9D9",
                          borderRadius: "10px",
                          minWidth: "150px",
                          textTransform: "none",
                          margin: "0 auto",
                          flexShrink: 0,
                          padding: "0.4rem 0.75rem",
                        }}
                        onClick={() => toggle("login")}
                      >
                        Login / Register
                      </Button>
                    </Box>
                  )}
                  <Grid
                    container
                    sx={{
                      justifyContent: "center",
                      spacing: { xs: 0, sm: 1 },
                      textAlign: { xs: "center", sm: "unset" },
                    }}
                  >
                    <Grid item xs={12} sm={6} md={8}>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: 14,
                          py: { xs: "20px", md: "10px" },
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Link
                          to="/"
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          Home
                        </Link>
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: 14,
                          py: { xs: "20px", md: "10px" },
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Link
                          to="/categories"
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          Categories
                        </Link>
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: 14,
                          py: { xs: "20px", md: "10px" },
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Link
                          to="/faqs"
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          FAQs
                        </Link>
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: 14,
                          py: { xs: "20px", md: "10px" },
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Link
                          to="/refund-policy"
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          Refund Policy
                        </Link>
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: 14,
                          py: { xs: "20px", md: "10px" },
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Link
                          to="/about-us"
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          About Us
                        </Link>
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: 14,
                          py: { xs: "20px", md: "10px" },

                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Link
                          to="/contact-us"
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          Contact Us
                        </Link>
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: 14,
                          py: { xs: "20px", md: "10px" },
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Link
                          to="/privacy-policy"
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          Privacy Policy
                        </Link>
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: 14,
                          width: "max-content",
                          margin: "auto",
                          py: { xs: "20px", md: "10px" },
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Link
                          to="/terms-and-conditions"
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          Terms and Conditions
                        </Link>
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: 14,
                          py: { xs: "20px", md: "10px" },
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Link
                          to="/dmca"
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          DMCA
                        </Link>
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: 14,
                          py: { xs: "20px", md: "10px" },
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Link
                          to="/preorder-terms-and-conditions"
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          Preorder T&C
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    sx={{ marginTop: "20px !important" }}
                  >
                    <a
                      href="https://www.facebook.com/DirectCertify/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={fbIcon}
                        alt="Facebook"
                        width="32"
                        height="32"
                        style={{ verticalAlign: "middle" }}
                      />
                    </a>
                    <a
                      href="https://twitter.com/directcertify"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={twitterIcon}
                        alt="Twitter"
                        width="32"
                        height="32"
                        style={{ verticalAlign: "middle" }}
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/directcertify/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={linkedinIcon}
                        alt="LinkedIn"
                        width="32"
                        height="32"
                        style={{ verticalAlign: "middle" }}
                      />
                    </a>
                    <a
                      href="https://www.pinterest.co.uk/directcertify0/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={pinterestIcon}
                        alt="Pinterest"
                        width="32"
                        height="32"
                        style={{ verticalAlign: "middle" }}
                      />
                    </a>
                  </Stack>
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "white",
                      fontSize: 12,
                      lineHeight: "14px",
                    }}
                  >
                    © {new Date().getFullYear()} All Rights Reserved
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        {state.login && (
          <Suspense fallback={<div>Loading...</div>}>
            <Login
              open={state.login}
              onClose={() => toggle("login")}
              onReg={() => toggle("register")}
            />
          </Suspense>
        )}
      </Container>
    </Box>
  );
}

export default Footer;
